import {AxiosError} from 'axios';
import {UserService} from "../services/UserService";
import {ISignUp} from "../../data/utility/SignUp";
import {ICurrentUser, IUserProfile} from "../../data/login/userProfile";
import {ErrorHandler} from "../../utils/ErrorHandler";
import moment from "moment";
import Roles from "../../data/login/Roles";
import {IProductItem} from "../../data/models/productItem";
import toast from "react-hot-toast";


/**
 *  This is a controller class, that utilizes the service of a data model.
 *  This is where manipulation of data can be done either before or after sending data.
 **/
export class UserController{
    private uService:UserService;

    constructor(){
        this.uService = new UserService()
    }


    // Sends a POST request to create a module
    public async Create(userData : ICurrentUser)
    {
        try{
            return this.uService.Post(userData);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    // sends a GET request to get an array of modules
    public async GetUser(id: string)
    {
        try{

            let data = {id:id}

            return this.uService.Get(data);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async GetUsers(): Promise<IUserProfile[]>
    {
        try{
            let value = await this.uService.GetAll();
            return value;
        }catch(error){

            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    // public async UpdateUser(user : ICurrentUser)
    // {
    //     try{
    //
    //         const params = {id:user.id}
    //         return await this.uService.Put(params,user);
    //     }catch(error){
    //         return ErrorHandler.catchApiError(error as AxiosError);
    //     }
    // }

    public async DeleteUser(id : string)
    {
        try{
            return await this.uService.Delete(id);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError)
        }
    }

   public async Authenticate(username:string, password:string, oldPassword:string)
   {
       try{
           return await this.uService.Login(username,password, oldPassword);
       }catch(error){
           return ErrorHandler.catchApiError(error as AxiosError);
       }
       
   }
    public async UpdatePasswordAndAuthenticate(username:string, password:string, oldPassword:string)
    {
        try{
            return await this.uService.UpdatePasswordAndAuthenticate(username,password, oldPassword);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }

    }
    public async SendResetRequest(targetEmail:string):Promise<boolean>
    {
        try{
            let data = {
                email: targetEmail
            };

            return await this.uService.SendResetRequest(data);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
   public async CheckStatus():Promise<IUserProfile>
    {
        try{
            return await this.uService.CheckStatus();
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    public async UpdateUserInfo(user: IUserProfile): Promise<IUserProfile[] | never> {
        try {
            let data={
                id:user.id,
                model:user
            }

            let response = await this.uService.UpdateUserInfo(data);
            toast.success(" saved!");
            return response

        } catch (error) {
            toast.error(" failed to save.");
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateUserPassword(product: IUserProfile): Promise<IUserProfile[] | never> {
        try {
            let data={
                id:product.id,
                model:product
            }

            let response = await this.uService.UpdateUserPassword(data);
            toast.success(" saved!");
            return response

        } catch (error) {
            toast.error(" failed to save.");
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async Logout():Promise<IUserProfile>
    {
        try{
            return await this.uService.Logout();
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    /**
     * Sends a user's signup data to the API which creates a new user
     * @param signUpData
     * @returns Id
     */
    public async SignUp(signUpData: ISignUp)
    {
        try{

            let newUser :IUserProfile = {
                id: '',
                firstName:signUpData.firstName,
                lastName:signUpData.firstName,
                name:signUpData.username,
                prefix:signUpData.username,
                email:signUpData.email,
                password:signUpData.password,
                description:'A test person',
                passwordExpired:false,
                createdAt: moment().format(),
                passwordLastChanged: moment().format(),
                lastModified: moment().format(),
                role: Roles.user,
            }

            return await this.uService.SignUp(newUser);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }

    }
    
}


