// project import
import becksProduct from './becksProduct';
import { NavItemType } from '../types/menu';
import winfieldProduct from './winfieldProduct';

// ==============================|| MENU ITEMS ||============================== //

export const becksItems: { items: NavItemType[] } = {
  items: [becksProduct]
};
export default becksItems;

export const winfieldsItems: { items: NavItemType[] } = {
  items: [winfieldProduct]
};
