import {useEffect, useState} from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import {
    EditOutlined,
    ProfileOutlined,
    LogoutOutlined,
    UserOutlined,
    UnorderedListOutlined,
    InstagramOutlined,
    NotificationOutlined
} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import useConfig from "../../../../../hooks/useConfig";
import useAuth from "../../../../../hooks/useAuth";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useNavigate();
    const {user} = useAuth();

    useEffect(()=>{
        if(user == null){
            history('/login')
        }
    })

    const getUserId=()=>{

        if(user != null)
        {
            if(user.id != null){
                return user.id
            }
        }

        return '';
    }

    const isUserAdmin =()=>{

        if(user != null)
        {
            if(user.role >1){
                return true
            }
        }
        return false;
    }

    const navTo = (loc:string)=>
    {
        let url = '/'+loc+"/"+getUserId();
        history(url);
    }

    return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>

      <ListItemButton onClick={()=>navTo("editProfile")}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
        <ListItemButton  onClick={()=>navTo("editProfilePassword")}>
            <ListItemIcon>
                <UserOutlined />
            </ListItemIcon>
            <ListItemText primary="Edit Password" />
        </ListItemButton>
        { !isUserAdmin() ?(
            <>
            </>
        ):(
            <>
                <ListItemButton   onClick={()=>history('/userManagement')}>
                    <ListItemIcon>
                        <UnorderedListOutlined/>
                    </ListItemIcon>
                    <ListItemText primary="User Management" />
                </ListItemButton>
                <ListItemButton   onClick={()=>history('/registerInstagram')}>
                    <ListItemIcon>
                        <InstagramOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Instagram Management" />
                </ListItemButton>
                <ListItemButton   onClick={()=>history('/bannerManagement')}>
                    <ListItemIcon>
                        <NotificationOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Banner Management" />
                </ListItemButton>
            </>
        )}
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
