
export enum Category  {
   none =0,
   cakes = 1,
   iceCreams = 2,
   chocolates = 3,
   cookies = 4,
   truffles = 5,
   coffees = 6,
   goTexans = 7,
   seasonal = 8,
   starters =9,
   hamburgersHotdogs= 10,
   sandwiches =11,
   salads =12,
   classics =13,
   dinners=14,
   kids=15,
   sides=16,
   drinks=17,
}

export enum CategoryLinks{
   none ='/',
   cakes = '/cakes-cheesecakes-list',
   iceCreams = '/ice-cream-list',
   chocolates = '/chocolate-list',
   cookies = '/cookies-brownies-list',
   truffles = '/truffles-list',
   coffees = '/coffees-drinks-list',
   goTexans = '/texans-list',
   seasonal = '/seasonal-list',
   starters = '/starters-list',
   hamburgersHotdogs= '/hamburgers-hotdogs-list',
   sandwiches ='/sandwiches-list',
   salads ='/salads-list',
   classics ='/classics-list',
   dinners='/dinner-list',
   kids='/kids-list',
   sides='/sides-fries-list',
   drinks='/shakes-drinks-list',
}