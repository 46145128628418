// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ChromeOutlined, QuestionOutlined, StopOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';

import CoffeeUrl, { ReactComponent as Coffee } from '../assets/images/icons/Winfield_ShoptheShop_CoffeesDrinks.svg';
import CakeUrl, { ReactComponent as Cake } from '../assets/images/icons/Winfield_ShoptheShop_CakeAndCheesecake.svg';
import ChocolateUrl, { ReactComponent as Chocolate } from '../assets/images/icons/Winfield_ShoptheShop_Chocolate.svg';
import CookiesUrl, { ReactComponent as Cookie } from '../assets/images/icons/Winfield_ShoptheShop_Cookies.svg';
import TexanUrl, { ReactComponent as Texan } from '../assets/images/icons/Winfield_ShoptheShop_GoTexan.svg';
import SeasonUrl, { ReactComponent as Season } from '../assets/images/icons/Winfield_ShoptheShop_Seasonal.svg';
import TruffleUrl, { ReactComponent as Truffle } from '../assets/images/icons/Winfield_ShoptheShop_Truffles.svg';
import IceCreamUrl, { ReactComponent as IceCream } from '../assets/images/icons/Winfield_ShoptheShop_IceCreams.svg';

// type
import { NavItemType } from '../types/menu';

const icons = {
  coffee: Coffee,
  cake: Cake,
  chocolate: Chocolate,
  cookie: Cookie,
  texan: Texan,
  season: Season,
  truffle: Truffle,
  iceCream: IceCream
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const winfieldProduct: NavItemType = {
  id: 'group-products',
  type: 'group',
  children: [
    {
      id: 'cake',
      title: <FormattedMessage id="cakes" />,
      type: 'item',
      url: '/cakes-cheesecakes-list',
      icon: icons.cake,
      breadcrumbs:true
    },
    {
      id: 'iceCreams',
      title: <FormattedMessage id="iceCreams" />,
      type: 'item',
      url: '/ice-cream-list',
      icon: icons.iceCream,
      breadcrumbs:true
    },
    {
      id: 'chocolates',
      title: <FormattedMessage id="chocolates" />,
      type: 'item',
      url: '/chocolate-list',
      icon: icons.chocolate,
      breadcrumbs:true
    },
    {
      id: 'cookies',
      title: <FormattedMessage id="cookies" />,
      type: 'item',
      url: '/cookies-brownies-list',
      icon: icons.cookie,
      breadcrumbs:true
    },
    {
      id: 'truffles',
      title: <FormattedMessage id="truffles" />,
      type: 'item',
      url: '/truffles-list',
      icon: icons.truffle,
      breadcrumbs:true
    },
    {
      id: 'coffees',
      title: <FormattedMessage id="coffees" />,
      type: 'item',
      url: '/coffees-drinks-list',
      icon: icons.coffee,
      breadcrumbs:true
    },
    {
      id: 'goTexans',
      title: <FormattedMessage id="goTexans" />,
      type: 'item',
      url: '/texans-list',
      icon: icons.texan,
      breadcrumbs:true
    },
    {
      id: 'seasonal',
      title: <FormattedMessage id="seasonal" />,
      type: 'item',
      url: '/seasonal-list',
      icon: icons.season,
      breadcrumbs:true
    }
    // {
    //   id: 'register',
    //   title: <FormattedMessage id="register" />,
    //   type: 'item',
    //   url: '/register',
    //   target: true
    // }
  ]
};

export default winfieldProduct;
