import axios from "axios";
import { url } from "inspector";
import { BaseReactService } from "./interfaces/BaseReactService";
import {ErrorHandler} from "../../utils/ErrorHandler";

export class ImageManagementService extends BaseReactService {

    constructor() {
        super("ImageManager/");
    }

    /**
     * Uploads an image to an image management service
     */
    public async GetImageUrl() {
        const endPoint = 'ImageManager/GetDirectCreatorUploadUrl';

        try {
            return await this.CustomGet({id:""},endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
    }

}