// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ChromeOutlined, QuestionOutlined,YoutubeOutlined, StopOutlined ,MenuOutlined,RestOutlined,AlertOutlined,UsbOutlined,ClusterOutlined,RobotOutlined,SmileOutlined, FireOutlined} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';

import StarterIcon, { ReactComponent as starter } from '../assets/images/icons/BecksIcons_CMS_Starters.svg';
import HamburgerIcon, { ReactComponent as hamburger } from '../assets/images/icons/BecksIcons_CMS_HamburgersHotDogs.svg';
import KidsIcon, { ReactComponent as kids } from '../assets/images/icons/BecksIcons_CMS_KidsPrimeMeals.svg';
import PlatedIcon, { ReactComponent as played } from '../assets/images/icons/BecksIcons_CMS_PlatedClassics.svg';
import PrimeTimeIcon, { ReactComponent as primeTime } from '../assets/images/icons/BecksIcons_CMS_PrimeTimeDinners.svg';
import SandwichesIcon, { ReactComponent as sandwich } from '../assets/images/icons/BecksIcons_CMS_Sandwiches.svg';
import ShakesIcon, { ReactComponent as shake } from '../assets/images/icons/BecksIcons_CMS_ShakesDrinks.svg';
import SideFriesIcon, { ReactComponent as sides } from '../assets/images/icons/BecksIcons_CMS_SidesFries.svg';
import SaladIcon, {ReactComponent as salads} from '../assets/images/icons/BecksIcons_CMS_FreshSalads.svg';


// icons
const icons = {
    StarterIcon:starter,
    HamburgerIcon:hamburger,
    KidsIcon:kids,
    PlatedIcon:played,
    PrimeTimeIcon:primeTime,
    SandwichesIcon:sandwich,
    ShakesIcon:shake,
    SideFriesIcon:sides,
    SaladIcon:salads
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const becksProduct: NavItemType = {
    id: 'BecksProduct',
    title: <FormattedMessage id="products" />,
    type: 'group',
    children: [
        {
            id: 'starters',
            title: <FormattedMessage id="starters-list" />,
            type: 'item',
            url: '/starters-list',
            icon: icons.StarterIcon
        },
        {
            id: 'hamburgersHotdogs',
            title: <FormattedMessage id="hamburgersHotdogs-list" />,
            type: 'item',
            url: '/hamburgers-hotdogs-list',
            icon: icons.HamburgerIcon
        },
        {
            id: 'sandwiches',
            title: <FormattedMessage id="sandwiches-list" />,
            type: 'item',
            url: '/sandwiches-list',
            icon: icons.SandwichesIcon
        },
        {
            id: 'salads',
            title: <FormattedMessage id="salads-list" />,
            type: 'item',
            url: '/salads-list',
            icon: icons.SaladIcon
        },
        {
            id: 'classics',
            title: <FormattedMessage id="classics-list" />,
            type: 'item',
            url: '/classics-list',
            icon: icons.PlatedIcon
        },
        {
            id: 'dinners',
            title: <FormattedMessage id="dinners-list" />,
            type: 'item',
            url: '/dinner-list',
            icon: icons.PrimeTimeIcon
        },
        {
            id: 'kids',
            title: <FormattedMessage id="kids-list" />,
            type: 'item',
            url: '/kids-list',
            icon: icons.KidsIcon
        },
        {
            id: 'sides',
            title: <FormattedMessage id="sides-list" />,
            type: 'item',
            url: '/sides-fries-list',
            icon: icons.SideFriesIcon
        },
        {
            id: 'drinks',
            title: <FormattedMessage id="drinks-list" />,
            type: 'item',
            url: '/shakes-drinks-list',
            icon: icons.ShakesIcon
        },
    ]
};

export default becksProduct;
