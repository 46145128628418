import ReactDOM from 'react-dom';

import {BrowserRouter} from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persister } from './store';
import { ConfigProvider } from './contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
        <>
            <ReduxProvider store={store}>
                {/*<PersistGate loading={null} persistor={persister}>*/}
                    <ConfigProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </ConfigProvider>
                {/*</PersistGate>*/}
            </ReduxProvider>
        </>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
