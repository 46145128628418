export type ThemeDirection = 'ltr' | 'rtl';
export type ThemeMode = 'light' | 'dark';
export type CompanySelection = 'Pixel' | 'Becks' | 'Winfields';
export type FontFamily = `'Inter', sans-serif` | `'Poppins', sans-serif` | `'Roboto', sans-serif` | `'Public Sans', sans-serif`;
export type PresetColor = 'default' | 'theme1' | 'theme2' | 'theme3' | 'theme4' | 'theme5' | 'theme6' | 'theme7' | 'theme8';
export type I18n = 'en' | 'fr' | 'ro' | 'zh'; // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese

export const drawerWidth = 260;

// ==============================|| THEME CONFIG  ||============================== //
// types
export type DefaultConfigProps = {
    defaultPath: string;
    fontFamily: FontFamily;
    i18n: I18n;
    miniDrawer: boolean;
    container: boolean;
    mode: ThemeMode;
    presetColor: PresetColor;
    themeDirection: ThemeDirection;
    companyDirection: CompanySelection;
};

const config: DefaultConfigProps = {
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
    companyDirection: 'Pixel',
};

export type CustomizationProps = {
    defaultPath: string;
    fontFamily: FontFamily;
    i18n: I18n;
    miniDrawer: boolean;
    container: boolean;
    mode: ThemeMode;
    presetColor: PresetColor;
    themeDirection: ThemeDirection;
    companyDirection: CompanySelection;
    onChangeContainer: VoidFunction;
    onChangeLocalization: (lang: I18n) => void;
    onChangeMode: (mode: ThemeMode) => void;
    onChangePresetColor: (theme: PresetColor) => void;
    onChangeDirection: (direction: ThemeDirection) => void;
    onChangeMiniDrawer: (miniDrawer: boolean) => void;
    onChangeFontFamily: (fontFamily: FontFamily) => void;
    onChangeCompany: (companyDirection: CompanySelection, presetColor: PresetColor) => void;
};


export default config;
