import React, {useEffect, useState} from 'react';
import moment from 'moment';
import FileArtifact, {defaultFileArtifact, IFileArtifact} from '../../../data/models/fileArtifact';
import {ImageFileType, ImageHandler, ImageVariant} from '../../../utils/ImageHandler';
import {DeepCopy} from '../../../utils/DeepCopy';
import {ArtifactType} from '../../../data/utility/artifactType';
import {ArtifactLocation} from '../../../data/utility/artifactLocation';
import {ImageManagementController} from '../../../_api/controllers/ImageManagementController';
import {DeleteTwoTone, DownOutlined, WarningOutlined, EyeOutlined, UploadOutlined, UpOutlined} from '@ant-design/icons';
import {useTheme} from '@mui/material/styles';
import MainCard from '../../MainCard';
import {
  Box, Button, Card, CardActions, CardContent,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import BounceLoader from "react-spinners/BounceLoader";

type ArtifactListConfig = {
  accepts: ArtifactType;
  location: ArtifactLocation;
  fileCountMax: number;
  fileSize: number;
};

type ArtifactListUploaderState = {
  artifactList: IFileArtifact[];
  isUploading: boolean;
  readyToUpload: boolean;
};

type ArtifactListUploaderProps = {
  isLoading: boolean;
  readyToUpload:boolean;
  id?: string;
  config: ArtifactListConfig;
  artifactList: IFileArtifact[];
  updateList: (fileList: IFileArtifact[]) => void;
  modalOptions: modalOptions;
};

export type modalOptions ={
  variant: ImageVariant;
  style: any;
}
type imageViewProps = {
  artifact: IFileArtifact;
  variant: ImageVariant;
};



export const ImageView: React.FC<imageViewProps> = (props) => {
  return (
      <>
        {props.artifact.id === '' ? (
            <div className="GridModelInternalProperties">no image</div>
        ) : (
            <div className="GridModelInternalProperties">
              <img alt={props.artifact.name} src={ImageHandler.GetImage(props.artifact.id, props.variant)} />
            </div>
        )}
      </>
  );
};

const ArtifactListUploader: React.FC<ArtifactListUploaderProps> = (props) => {
  const theme = useTheme();
  const imageManagementController = new ImageManagementController();
  const defaultState: ArtifactListUploaderState = {
    artifactList: [],
    isUploading: false,
    readyToUpload:false,
  };

  const [state, setState] = useState(defaultState);
  const [artifactView, setArtifactView] = useState(defaultFileArtifact);
  const [buttonHover, setButtonHover] = useState(false);
  const defaultProps = {
    config: {
      location: ArtifactLocation.CloudFlare,
      fileCountMax: 20,
      accepts: ArtifactType.Image,
      fileSize: 5000000
    },
    id: 'multiInputImageUpload'
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      artifactList: props.artifactList,
      readyToUpload: props.readyToUpload
    }));
  }, [props, state.isUploading, props.readyToUpload]);

  //#region Validation
  const performValidations = (file: File) => {
    if (!file) {
      return false;
    }

    //TODO: UPDATE TO ALLOW FOR MORE FILE TYPES
    if (validateImageFile(file.type) && validateImageSize(file.size)) {
      return true;
    } else {
      return false;
    }

    //TODO add dimensions validation
  };
  const validateImageFile = (fileType: string) => {
    let isValid = false;

    const validTypeList: string[] = Object.values(ImageFileType);

    validTypeList.forEach((type) => {
      if (type == fileType) {
        isValid = true;
      }
    });

    if (!isValid) {
      //TODO set state for errors
      throw new Error('Invalid Image Type');
    }

    return isValid;
  };
  const validateImageSize = (fileSize: number) => {
    if (fileSize > props.config.fileSize) {
      //TODO set state for errors
      throw new Error('Image must be 5 MB or less');
    }

    return true;
  };
  //#endregion
  //#region Change Handlers
  const handleFileInputChange = async (e: React.BaseSyntheticEvent) => {
    const fileList: FileList = e.target.files;
    if (fileList.length < 0) {
      // TODO throw some type of error
      return;
    }

    const file = fileList.item(0);

    // because type script yells at us even though we are validating the file in performValidations
    if (!file) {
      return;
    }

    // perform image validations
    if (!performValidations(file)) {
      return;
    }

    //Set Parents ImageUrl
    setState((prevState) => ({
      ...prevState,
      isUploading: true
    }));
    let returnId: string = '';
    let value = await GetDirectImageUploadUrl(file);
    if (value == undefined) {
      returnId = '';
    } else {
      returnId = value;
    }

    setState((prevState) => ({
      ...prevState,
      isUploading: false
    }));

    if (props.config.fileCountMax <= 1) {
      let newFile: FileArtifact = {
        fileType: props.config.accepts,
        fileLocation: props.config.location,
        id: returnId,
        name: file.name,
        lastModified: moment().format(),
        createdAt: moment().format()
      };

      let list: IFileArtifact[] = [];
      list.push(newFile);

      props.updateList(list);
    } else {
      let newFile: FileArtifact = {
        fileType: props.config.accepts,
        fileLocation: props.config.location,
        id: returnId,
        name: file.name,
        lastModified: moment().format(),
        createdAt: moment().format()
      };

      let list = DeepCopy.copy(state.artifactList);
      list.push(newFile);

      props.updateList(list);
    }
  };

  const handleImageRemoval = (artifact: IFileArtifact, index: number) => {
    if(artifact.id !==''){
      if (props.config.fileCountMax <= 1)
      {
        imageManagementController.DeleteImage(artifact.id);

        let newFile: FileArtifact = {
          fileType: props.config.accepts,
          fileLocation: props.config.location,
          id: '',
          name: 'N/A',
          lastModified: moment().format(),
          createdAt: moment().format()
        };

        let list: IFileArtifact[] = [];
        list.push(newFile);

        setState((prevState) => ({
          ...prevState,
          artifactList: list
        }));

        props.updateList(list);
      } else {
        imageManagementController.DeleteImage(artifact.id);

        const list = DeepCopy.copy(state.artifactList);
        list.splice(index, 1);

        setState((prevState) => ({
          ...prevState,
          artifactList: list
        }));

        props.updateList(list);
      }
    }
  };

  //#endregion
  //#region Data Manipulators
  const UploadImage = async (uploadUrl: string, file: File) => {
    const uploadResults = await imageManagementController.UploadImage(uploadUrl, file);
    return uploadResults;
  };

  const GetDirectImageUploadUrl = async (file: File) => {
    const res: { id: string; uploadUrl: string } | undefined = await imageManagementController.GetDirectUploadUrl();

    if (res) {
      if (res.uploadUrl) {
        const uploadRes = await UploadImage(res.uploadUrl, file);
      }
    }

    return res?.id;
  };

  const moveArtifactHigher = async (artifact: IFileArtifact, index: number) => {
    if (index + 1 <= state.artifactList.length - 1) {
      let artifactList = state.artifactList;
      artifactList.splice(index, 1);
      artifactList.splice(index + 1, 0, artifact);

      props.updateList(artifactList);
    }
  };
  const moveArtifactLower = async (artifact: IFileArtifact, index: number) => {

    if (index - 1 >= 0) {
      let artifactList = state.artifactList;
      artifactList.splice(index, 1);
      artifactList.splice(index - 1, 0, artifact);

      props.updateList(artifactList);
    }
  };
  //#endregion


  //#region modal
  const [warnModalOpen, setWarnModalOpen] = React.useState(false);
  const [artifactToDelete, setArtifactToDelete] = React.useState(defaultFileArtifact);
  const [indexToDelete, setIndexToDelete] = React.useState(-1);

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleWarningClose = () => setWarnModalOpen(false);
  const handleClose = () => setModalOpen(false);

  type modalProps ={
    artifact: IFileArtifact
    variant: ImageVariant
    modalStyle: any
  }

  type warningModalProps={
     artifact: IFileArtifact,
     index: number
  }

  const ImageModal: React.FC<modalProps> = (props) => {
    const modalStyle = {
      position: 'absolute',
      top:'50%',
      left:'50%',
      transform: 'translate(-50%, -50%)',
      width: '991px',
      height:'661px',
      bgcolor: 'white',
      boxShadow: '24',
    }
    const getModalStyle =()=>{
      if(props.modalStyle == null){
        return modalStyle;
      }else{
        return props.modalStyle;
      }
    }

    return(
        <>
          <div>
            <Modal open={modalOpen}
                   onClose={handleClose}
            >
              <Box sx={getModalStyle}>
                <ImageView artifact={props.artifact}
                            variant={props.variant}/>
              </Box>
            </Modal>
          </div>
        </>
        )
  }
  const WarningDeleteArtifactModal: React.FC<warningModalProps> = (props) =>
  {
    const modalStyle = {
      position: 'absolute',
      top:'50%',
      left:'50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'white',
      boxShadow: '24',
      border:'1 solid black',
      borderRadius:'15px'
    }
    return(
        <>
          <div>
            <Modal open={warnModalOpen}
                   onClose={handleWarningClose}
            >
              <Card sx={modalStyle} variant="outlined">
                <CardContent>

                  <Typography gutterBottom variant="h4" component="div">
                    Are you sure you would like to delete this image?
                  </Typography>
                </CardContent>
                <CardActions sx={{
                  justifyContent:'end'
                }}>
                  <Button sx={{
                    background:'red',
                    color:'white',
                    borderRadius:'5px',
                    "&:hover":{
                      background:'darkred',
                      color:'white'
                    }
                  }}
                          color="error" size="small" onClick={(e)=>runDeleteAction(artifactToDelete,indexToDelete)}>
                    Yes
                  </Button>
                  <Button sx={{
                    background:'green',
                    color:'white',
                    borderRadius:'5px',
                    "&:hover":{
                      background:'darkgreen',
                      color:'white'
                    }
                  }}
                          color="primary" size="small" onClick={handleWarningClose}>
                    No
                  </Button>
                </CardActions>


              </Card>
            </Modal>
          </div>
        </>
    )
  }
  const openModal =(artifact: IFileArtifact)=>
  {
    setArtifactView(artifact);
    setModalOpen(true);
  }

  const runDeleteAction =(artifact:IFileArtifact, index:number)=>{
    handleImageRemoval(artifact, index);
    handleWarningClose()
  }
  const openWarningModal = (artifact:IFileArtifact, index:number)=>
  {
    setArtifactToDelete(artifact);
    setIndexToDelete(index);
    setWarnModalOpen(true);
  }

  //#endregion
  return (
    <>
      {!props.isLoading ? (
        <>
          <MainCard>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">

                  {!state.isUploading &&(
                      <>
                        {state.readyToUpload? (
                            <>
                              <Tooltip title={'Upload new image'}>
                                <label
                                    onMouseEnter={(e)=>setButtonHover(true)}
                                    onMouseLeave={(e)=>setButtonHover(false)}
                                    htmlFor={props.id}
                                    style={{
                                      margin: 0,
                                      backgroundColor: !buttonHover?'#dcc764':'#a9984c',
                                      color:'white',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      fontWeight:'600',
                                      cursor: 'pointer',
                                      width: '84px',
                                      height: '32px',
                                      border: '0',
                                      borderRadius: '5px'
                                    }}
                                >

                                  <div style={{position:'relative', left:'7px', top:'5px'}}>
                                    <UploadOutlined style={{ color: 'white'}} />&nbsp;
                                    Upload
                                  </div>
                                </label>

                              </Tooltip>
                              <input
                                  disabled={state.isUploading && !state.readyToUpload}
                                  id={props.id}
                                  type="file"
                                  onChange={(e) => handleFileInputChange(e)}
                                  style={{ opacity: 0, width: 0, marginLeft:'0px' }}
                                  accept="image/png, image/jpeg"
                              />
                            </>
                        ):(
                            <Tooltip title={'To enable please add product name and select category'}>
                              <label
                                  htmlFor={props.id}
                                  style={{
                                    margin: 0,
                                    backgroundColor: "gray",
                                    color:'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    width: '84px',
                                    height: '32px',
                                    border: '0',
                                    borderRadius: '5px'
                                  }}
                              >

                                <div style={{position:'relative', left:'7px', top:'5px'}}>
                                  <UploadOutlined style={{ color: 'white'}} />&nbsp;
                                  Upload
                                </div>
                              </label>

                            </Tooltip>
                        )}


                      </>
                  )}

                </Stack>
              </Grid>
              <Grid item xs={12}>
                {!state.isUploading ? (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {props.config.fileCountMax > 1 ?(
                              <TableCell style={{width:'50px'}} align="center">
                                Change Position
                              </TableCell>
                          ):(
                              <TableCell style={{width:'90px'}} align="center">
                                &nbsp;
                              </TableCell>
                          )}
                          <TableCell style={{width:'100px'}} align="center">
                            Image
                          </TableCell>
                          <TableCell align="center">
                            Name of File
                          </TableCell>
                          <TableCell style={{width:'100px'}} align="center">
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                    {state.artifactList.map((artifact, index) => (
                      <TableRow key={props.id+":"+index}>

                          {props.config.fileCountMax > 1 ? (
                            <>
                              <TableCell align='center'>
                                <Tooltip title="Move Image Higher In Order">
                                  <IconButton
                                      color="default"
                                      onClick={(e: any) => moveArtifactLower(artifact, index)}>
                                    <UpOutlined twoToneColor={theme.palette.error.main} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Move Image Lower In Order">
                                  <IconButton
                                      color="default"
                                      onClick={(e: any) => moveArtifactHigher(artifact, index)}>
                                    <DownOutlined twoToneColor={theme.palette.action.active} />
                                  </IconButton>
                                </Tooltip>

                              </TableCell>
                            </>
                          ):(
                              <TableCell align='center'>

                              </TableCell>
                          )}
                        <TableCell align='center'>
                          <Tooltip title="Image">
                            <div  onClick={(e: any) => openModal(artifact)}>
                              <ImageView  artifact={artifact}
                                          variant={ImageVariant.admin}/>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='center'>
                          <Typography>{artifact.name}</Typography>
                        </TableCell>
                         <TableCell align='center'>
                           <Tooltip title="Delete">
                             <IconButton
                                 color="error"
                                 onClick={(e:any)=> openWarningModal(artifact,index)}>
                             {/*// onClick={(e: any) => handleImageRemoval(artifact, index)}>*/}

                               <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                             </IconButton>
                           </Tooltip>

                         </TableCell>
                      </TableRow>
                    ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <>
                    <div style={{position:'relative', top:'25%', height:'100%', margin:'0 auto'}}>
                      <BounceLoader color={theme.palette.primary.main}  />
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </MainCard>
          <WarningDeleteArtifactModal artifact={artifactToDelete}
                                      index={indexToDelete}/>
        </>
      ):(
          <>
            <div style={{position:'relative', top:'25%', height:'100%', margin:'0 auto'}}>
              <BounceLoader color={theme.palette.primary.main}  />
            </div>
          </>
      )}
      { props.modalOptions == null?(
          <>
          </>
      ):(
          <>
            <ImageModal artifact={artifactView}
                        variant={props.modalOptions.variant}
                        modalStyle={props.modalOptions.style}/>
          </>
      )}

    </>
  );
};

export default ArtifactListUploader;
