
export enum ImageVariant{
    default = '/public',
    admin='/dashboardTableLarge',
    thumbnail ='/thumbnail',
    modal = '/modalCover',
    favorite = '/favoriteCover'
}

export enum ImageFileType{
    png ='image/png',
    jpeg ='image/jpeg'
}

export type DirectUploadResults = {
    result:{id:string, uploadUrl:string}
    result_info:string
    success:boolean
    errors: string[]
    messages: string[]
}

const baseImageUrl = `https://imagedelivery.net/09WJm76jiCh0iW64EDwhfw/`

export class ImageHandler{
    public static GetImage = (imageId:string, variantName:ImageVariant = ImageVariant.default ):string => {
        let url = baseImageUrl+imageId+variantName;
        return url;
    }
}