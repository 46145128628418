import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import WinfieldsRoutes from './WinfieldRoutes';
import BecksRoutes from './BecksRoutes';
import useConfig from "../hooks/useConfig";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // const {companyDirection} = useConfig();
  // let route = BecksRoutes;
  //
  // if(companyDirection == 'Winfields'){
  //   route = WinfieldsRoutes;
  // }

  return useRoutes([WinfieldsRoutes, BecksRoutes, LoginRoutes]);
}
