import React from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import {JWTProvider as Authprovider} from './contexts/JWTContext';
import Snackbar from './components/@extended/Snackbar';
import {Toaster} from "react-hot-toast";
import {useTheme} from '@mui/material/styles';

const App =() =>{

    return (
        <>
            <ThemeCustomization>
                <Locales>
                    <Authprovider>
                        <>
                            <ToastedRouter/>
                        </>
                    </Authprovider>
                </Locales>
            </ThemeCustomization>

        </>
  );
}

const ToastedRouter =() =>{

    const theme = useTheme();
    return(
        <>
            <Routes/>
            <Toaster position="bottom-right"
                     reverseOrder={false}
                     toastOptions={{
                         style:{
                             height:"100px",
                             color:theme.palette.common.white
                         },
                         success:{
                             style:{
                                 background: theme.palette.primary.light,
                                 color: theme.palette.common.white
                             }
                         },
                         error:{
                             style:{
                                 background: theme.palette.error.light,
                                 color: theme.palette.common.white
                             }
                         }
                     }}
            />
        </>
    )
}

export default App;
