import { lazy } from 'react';

// project import
import GuestGuard from '../utils/route-guard/GuestGuard';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from "../utils/route-guard/AuthGuard";
import MainLayout from "../layout/MainLayout";

// render - login
const InstagramAuth = Loadable(lazy(()=> import('../pages/auth/instagram-authorization')));
const BannerManagement = Loadable(lazy(()=> import('../pages/banner/banner-management')));
const AuthLogin = Loadable(lazy(() => import('../pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('../pages/auth/register')));
const EditProfile = Loadable(lazy(()=>import('../pages/userProfile/edit-profile')))
const EditProfilePassword = Loadable(lazy(()=>import('../pages/userProfile/edit-profile-password')))
const UserManagement = Loadable(lazy(()=>import('../pages/userProfile/user-management')))
const ProductEditor = Loadable(lazy(()=> import('../pages/product-pages/product-edit')));
const ForgetPassword = Loadable(lazy(()=> import('../pages/auth/forgot-password')));
const PasswordReset = Loadable(lazy(()=> import('../pages/auth/password-reset')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: '/home',
          element: <AuthLogin />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'forgot-password',
          element: <ForgetPassword />
        },
        {
          path: 'password-reset/:id',
          element: <PasswordReset />
        }
      ]
    },
    {
      path: '/',
      element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
      ),
      children: [
        {
          path: 'editProfile/:id',
          element: <EditProfile />
        }
        ,
        {
          path: 'editProfilePassword/:id',
          element: <EditProfilePassword />
        } ,
        {
          path: 'userManagement',
          element: <UserManagement />
        },
        {
          path: 'product-details/:id',
          element: <ProductEditor />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'registerInstagram',
          element: <InstagramAuth/>
        },
        {
          path: 'bannerManagement',
          element: <BannerManagement/>
        }
      ]
    }
  ]
};

export default LoginRoutes;
