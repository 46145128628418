import { createContext, ReactNode } from 'react';
import useLocalStorage from "../hooks/useLocalStorage";

// project import
import config from "../config";

// types
import { CompanySelection, CustomizationProps, FontFamily, I18n, PresetColor, ThemeDirection, ThemeMode } from '../config';

// initial state
const initialState: CustomizationProps = {
    ...config,
    onChangeContainer: () => {},
    onChangeLocalization: (lang: I18n) => {},
    onChangeMode: (mode: ThemeMode) => {},
    onChangePresetColor: (theme: PresetColor) => {},
    onChangeDirection: (direction: ThemeDirection) => {},
    onChangeMiniDrawer: (miniDrawer: boolean) => {},
    onChangeFontFamily: (fontFamily: FontFamily) => {},
    onChangeCompany: (companyDirection: CompanySelection, presetColor) => {},
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('becks-admin-config', initialState);

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container
        });
    };

    const onChangeLocalization = (lang: I18n) => {
        setConfig({
            ...config,
            i18n: lang
        });
    };

    const onChangeMode = (mode: ThemeMode) => {
        setConfig({
            ...config,
            mode
        });
    };

    const onChangePresetColor = (theme: PresetColor) => {
        setConfig({
            ...config,
            presetColor: theme
        });
    };

    const onChangeDirection = (direction: ThemeDirection) => {
        setConfig({
            ...config,
            themeDirection: direction
        });
    };

    const onChangeMiniDrawer = (miniDrawer: boolean) => {
        setConfig({
            ...config,
            miniDrawer
        });
    };

    const onChangeFontFamily = (fontFamily: FontFamily) => {
        setConfig({
            ...config,
            fontFamily
        });
    };
    const onChangeCompany = (companyDirection: CompanySelection, presetColor: PresetColor) => {
        setConfig({
            ...config,
            presetColor,
            companyDirection
        });
    };
    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeContainer,
                onChangeLocalization,
                onChangeMode,
                onChangePresetColor,
                onChangeDirection,
                onChangeMiniDrawer,
                onChangeFontFamily,
                onChangeCompany
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
