import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import toast from "react-hot-toast";

const baseLocalHostUrl = 'https://localhost:44386/api'; // Could get this from app settings
const baseDevHostUrl = 'https://becks-prime-api-dev-da3ce3637199.herokuapp.com/api';
const baseProdHostUrl = 'https://api.winfieldscb.com/api';
const baseTestHostUrl = '/api';

/**
 * This is a generic service class that give basic Crud operations to any service as long as the path is specified in the constructor
 */
interface IBaseReactService {
  baseAxiosConfiguration: AxiosRequestConfig;
  GetAll(specificLocation?: string): any;
  Get(params: any, specificLocation?: string): any;
  Put(model: any, specificLocation?: string): any;
  Post(model: any, specificLocation?: string): any;
  Delete(params: any, specificLocation?: string): void;
}

export class BaseReactService implements IBaseReactService {

  private baseUrl = '';
  private endPoint = '';
  private apiLocation = '';

  constructor(providedEndpoint: string) {
    if (process.env.REACT_APP_DEV_ENV === 'production') {
      this.baseUrl = baseProdHostUrl;
    }
    else if(process.env.REACT_APP_DEV_ENV === 'development') {
      this.baseUrl = baseDevHostUrl
      ;
    }else{
      this.baseUrl = baseLocalHostUrl;
    }

    this.endPoint = providedEndpoint;
    this.apiLocation = `${this.baseUrl}/${this.endPoint}`;
  }

  public baseAxiosConfiguration: AxiosRequestConfig = {
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' }
  };

  protected GetEndpoint(endpointLocation?: string, action?: string, id?: string) {
    var endpoint = `${this.baseUrl}`;
    if (endpointLocation != null) {
      endpoint += `/${endpointLocation}`;
    } else {
      endpoint = `${this.apiLocation}${action}`;
    }

    if (id != null) {
      endpoint += "/"+id;
    }

    return endpoint;
  }

  protected GerCoreEndpoint(action?: string, id?: string) {
    let endpoint = `${this.apiLocation}${action}/`;

    if (id !== null) {
      endpoint += id;
    }

    return endpoint;
  }

  protected GetConfigWithParams(params: any) {
    var config = this.baseAxiosConfiguration;
    config.params = params;
    return config;
  }

  //Calls Get with baseline config
  public async GetAll(specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.get(this.GetEndpoint(specificLocation, 'Get'), this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  //Set URL Parameters { Id: Value, other: Value2 }  =>  Server Will look like Get (string Id, any other)
  public async CustomGet(params: {}, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.get(this.GetEndpoint(specificLocation, `Get`), this.GetConfigWithParams(params));
      return response.data;
    } catch (error)
    {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async CustomGetNoParams( specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.get(this.GetEndpoint(specificLocation, `Get`), this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async Get(params: { id: string }) {
    try {
      var response: AxiosResponse = await axios.get(this.GerCoreEndpoint(`Get`, params.id), this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  //Calls Post with just a model
  public async Post(params: {}, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.post(this.GetEndpoint(specificLocation, 'Post'), params, this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async Put(id: string, model: any) {
    try {
      var response: AxiosResponse = await axios.put(this.GerCoreEndpoint(`Put`), model, this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async CustomPost(params: { model:any}, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.post(this.GetEndpoint(specificLocation, `Post`), params.model ,this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async CustomPut(params: {id:string, model:any}, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.put(this.GetEndpoint(specificLocation, `Put`, params.id), params.model ,this.baseAxiosConfiguration);
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  //Calls Delete with params
  public async Delete(id: string, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.delete(this.GerCoreEndpoint(`Delete`, id), this.baseAxiosConfiguration);
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  //Calls Delete with params
  public async DeleteItem(id: string, specificLocation?: string) {
    try {
      var response: AxiosResponse = await axios.delete(this.GerCoreEndpoint(`DeleteItem`, id), this.baseAxiosConfiguration);
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async UpdateOrder(pos:string, model:any, specificLocation?: string) {
    try {
      var params = {pos:pos, model:model}
      var response: AxiosResponse = await axios.put(this.GetEndpoint(specificLocation, `Put`), model,this.GetConfigWithParams(params));
      return response.data;
    } catch (error) {
      // @ts-ignore
      if(error.response.status == 401){
        toast.error("Unauthorized");
      }
      ErrorHandler.catchApiError(error as AxiosError);
    }
  }
}
