import ProductItem, { IProductItem } from '../../data/models/productItem';
import { Link, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { IFileArtifact } from '../../data/models/fileArtifact';
import { ImageHandler, ImageVariant } from '../../utils/ImageHandler';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableOptions, useExpanded, usePagination, useRowSelect, useTable } from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import MainCard from '../../components/MainCard';
import ScrollX from '../../components/ScrollX';
import {
  DraggableRow,
  HeaderSort,
  IndeterminateCheckbox,
  SortingSelect,
  TablePagination,
  TableRowSelection
} from '../../components/third-party/ReactTable';
import {
    Badge,
    Button, Card, CardActions, CardContent,
    Chip, Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { ProductItemsController } from '../../_api/controllers/ProductItemsController';
import { CloseOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { alpha, useTheme } from '@mui/material/styles';
import IconButton from '../@extended/IconButton';
import AdminProductGridView from './AdminProductGridView';
import { useSelector } from '../../store';
import { FormattedMessage } from 'react-intl';
import { Category } from '../../data/utility/category';
import {ImageView} from "../bp/uploader/ArtifactListUploader";
import {Status} from "../../data/utility/ItemStatus";
import toast from "react-hot-toast";
import BounceLoader from "react-spinners/BounceLoader";

type ProductGridView = {
  company: string;
  category: number;
  status: Status;
  propsToShow: { propName: string; dataType: string }[];
};

type ProductViewState = {
  products: ProductItem[];
  isLoading: boolean;
};

type TableHeader = {
  Header: string;
  accessor: string;
  className: string;
  Cell?: any;
}[];


const ProductGridView: React.FC<ProductGridView> = (props) => {
  const itemController: ProductItemsController = new ProductItemsController();
  const theme = useTheme();
  const [state, setState] = React.useState<ProductViewState>({
    products: [],
    isLoading: true
  });
  const history = useNavigate();

  const [records, setRecords] = useState(state.products);

  const { products } = useSelector((state) => state.product);

  useEffect(() => {
    const fetch = async (setState: any, setRecords: any) => {
      let comp = 1;
      if (props.company === 'Winfields') {
        comp = 2;
      }

      let response = await itemController.GetProductsByCategoryAndStatus(comp, props.category, props.status);

      console.log('count' + response.length);
      setState(() => ({
        isLoading: false,
        products: response
      }));
      setRecords(response);

      if (!state.isLoading) {
        console.log(records);
      }
    };

    try{
        fetch(setState, setRecords).catch();
    }catch(error){
        toast.error("Failed To Pull Records for Category: "+ Category[props.category]);
    }

  }, [props]);

  const preBuiltColumns: (
    | { Header: string; accessor: string; className: string }
    | { Header: string; accessor: string; Cell: ({ value }: any) => JSX.Element }
    | { Header: string; accessor: string; className: string; Cell: ({ value }: any) => JSX.Element }
      | { Header: string; className: string; disableSortBy: boolean; }
      | { Header: string; className: string; disableSortBy: boolean; Cell: ({ value }: any) => JSX.Element }
    | { Header: string; className: string; disableSortBy: boolean; Cell: ({ value, value2 }: any) => JSX.Element }

  )[] = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'thumbnailLink',
        className: 'cell-center',
        Cell: ({ value }: any) => {
          return(
            <>
              <ImageView variant={ImageVariant.admin}  artifact={value as IFileArtifact} />
            </>
          )

        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        className: 'cell-center cell-text-width'
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: 'cell-left'
      },
      {
        Header: 'Actions',
        className: 'cell-center',
        disableSortBy: true,
      }
    ],
    []
  );

  const getRowId = useCallback((row: any) => row.id, []);

  const onDrop = async (dragIndex: number, item: ProductItem) => {
    if (dragIndex !== item.order) {
      let response = await itemController.UpdateOrder(dragIndex.toString(), item);
      setRecords(response);
    }
  };
  const moveRow = (dragIndex: number, hoverIndex: number) => {

      const dragRecord = records[dragIndex];

    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord]
        ]
      })
    );
  };
  const updateRow = (index:number) => {
    setRecords(
        update(records, {
          $splice: [
            [index, 1],
          ]
        })
    );
  };
  const handleAddProduct = () => {
    history(`/product-details/C` + props.category);
  };
  const navigateToEdit = (id: string) => {
    let url = `/product-details/` + id;
    history(url);
  };


  const {
    getTableProps,
    getTableBodyProps,
    setHiddenColumns,
    headerGroups,
    getSubRows,
    allColumns,
    visibleColumns,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { selectedRowIds, pageIndex, pageSize },
    prepareRow
  } = useTable(
    {
      // @ts-ignore
      columns: preBuiltColumns,
      data: records,
      getRowId,
      initalState: { pageIndex: 0, pageSize: 5 }
    },
    useExpanded,
    usePagination,
    useRowSelect
  );

  function AddButton() {
    return (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAddProduct}>
          Add Product
        </Button>
      </Stack>
    );
  }

  const EditButtons =(row:any)=>{

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
          <Tooltip title="Edit">
            <IconButton
                color="primary"
                onClick={(e: any) => {
                  e.stopPropagation();

                  navigateToEdit(row.row.id);
                }}
            >
              <EditTwoTone twoToneColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
                color="error"
                 onClick={(e:any)=> {
                     e.stopPropagation()

                     openWarningModal(row.row.id,row.row.index)
                 }}
            >
              <DeleteTwoTone
                  twoToneColor={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        </Stack>
    );
  }

    const [warnModalOpen, setWarnModalOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState("");
    const [indexToDelete, setIndexToDelete] = React.useState(-1);

    const handleWarningClose = () => setWarnModalOpen(false);

    const openWarningModal = (id:string, index:number)=>
    {
        setIdToDelete(id);
        setIndexToDelete(index);
        setWarnModalOpen(true);
    }

    const handleRemoval =(id:string, index:number)=>
    {
        itemController.DeleteProductItem(id);
        updateRow(index);

        handleWarningClose();
    }

    type warningModalProps={
        id: string,
        index: number
    }
    const WarningDeleteArtifactModal: React.FC<warningModalProps> = (props) =>
    {
        const modalStyle = {
            position: 'absolute',
            top:'50%',
            left:'50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: '24',
            border:'1 solid black',
            borderRadius:'15px'
        }
        return(
            <>
                <div>
                    <Modal open={warnModalOpen}
                           onClose={handleWarningClose}
                    >
                        <Card sx={modalStyle} variant="outlined">
                            <CardContent>

                                <Typography gutterBottom variant="h4" component="div">
                                    Are you sure you would like to delete this product?
                                </Typography>
                            </CardContent>
                            <CardActions sx={{
                                justifyContent:'end'
                            }}>
                                <Button sx={{
                                    background:'red',
                                    color:'white',
                                    borderRadius:'5px',
                                    "&:hover":{
                                        background:'darkred',
                                        color:'white'
                                    }
                                }}
                                        color="error" size="small" onClick={(e)=>handleRemoval(idToDelete,indexToDelete)}>
                                    Yes
                                </Button>
                                <Button sx={{
                                    background:'green',
                                    color:'white',
                                    borderRadius:'5px',
                                    "&:hover":{
                                        background:'darkgreen',
                                        color:'white'
                                    }
                                }}
                                        color="primary" size="small" onClick={handleWarningClose}>
                                    No
                                </Button>
                            </CardActions>


                        </Card>
                    </Modal>
                </div>
            </>
        )
    }

  function Title() {
    let newTitle = Category[props.category];
    newTitle += props.status;

    return(
        <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3"><FormattedMessage id={newTitle} /></Typography>
        </Stack>
    )
  }

  return (
    <>
        {state.isLoading ? (
            <div style={{ margin:'0 auto'}}>
                <BounceLoader color={theme.palette.primary.main}  />
            </div>
        ) : (
      <MainCard content={false} title={<Title />} secondary={<></>}>

          <ScrollX>
            <Stack spacing={3}>
              <DndProvider backend={HTML5Backend}>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        <TableCell />
                        {headerGroup.headers.map((column: any) => (
                          <TableCell {...column.getHeaderProps([{ className: column.className }])}>{column.render('Header')}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    {rows.map(
                      (row: any, index: number) =>
                        // @ts-ignore
                        prepareRow(row) || (
                          <DraggableRow row={row} index={index} moveRow={moveRow} droppedRow={onDrop} type={Status[props.status]} {...row.getRowProps()}>
                            {row.cells.map((cell: any, cellIndex:number) => (
                              <>
                                  {props.status == Status.Active?(
                                      <>
                                          {index <= 3 ?(
                                              <>
                                                  {cellIndex == 0? (
                                                      <>
                                                          {index <=1 ? (
                                                              <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                                                                  <Badge color={'info'} badgeContent={"Best"} anchorOrigin={{vertical:'bottom', horizontal:'right'}}>
                                                                      <Badge color={'error'} badgeContent={"Favorite"}>
                                                                          {cell.render('Cell')}
                                                                      </Badge>
                                                                  </Badge>
                                                              </TableCell>
                                                          ):(
                                                              <>
                                                                  <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                                                                      <Badge color={'error'} badgeContent={"Favorite"}>
                                                                          {cell.render('Cell')}
                                                                      </Badge>
                                                                  </TableCell>
                                                              </>
                                                          )}
                                                      </>
                                                  ):(
                                                      <>
                                                          {cellIndex ==3 ?(
                                                              <TableCell>
                                                                  <EditButtons row={row}/>
                                                              </TableCell>
                                                          ):(
                                                              <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>

                                                          )}
                                                      </>
                                                  )}
                                              </>
                                          ):(
                                              <>
                                                  {cellIndex ==3 ?(
                                                      <TableCell>
                                                          <EditButtons row={row}/>
                                                      </TableCell>
                                                  ):(
                                                      <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>

                                                  )}
                                              </>
                                          )}
                                      </>
                                  ):(
                                      <>
                                          {cellIndex == 3?(
                                              <>
                                                  <TableCell>
                                                      <EditButtons row={row}/>
                                                  </TableCell>
                                              </>
                                          ):(
                                              <>
                                                  <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                                                      {cell.render('Cell')}
                                                  </TableCell>
                                              </>
                                          )}


                                      </>
                                  )}

                              </>
                            ))}
                          </DraggableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </DndProvider>
            </Stack>
          </ScrollX>
          <WarningDeleteArtifactModal   id={idToDelete}
                                      index={indexToDelete}/>
      </MainCard>

        )}
    </>
  );
};
export default ProductGridView;
