import { AxiosError } from 'axios';
import { BaseReactService } from './interfaces/BaseReactService';
import { ICurrentUser, IUserProfile } from '../../data/login/userProfile';
import { ErrorHandler } from '../../utils/ErrorHandler';
import {IProductItem} from "../../data/models/productItem";
import {Ownership} from "../../data/utility/ownership";

/**
 * This is a User Data Service which gets data from the server with basic crud operations.
 * Adding additional functions here will allow the user to get to different routes if needed.
 */
export class UserService extends BaseReactService {
  constructor() {
    super('UserProfile/');
  }
  /**
   * Logins in a users
   * @param username
   * @param password
   * @returns A token or errors
   */
  public async Login(username: string, password: string, oldPassword:string): Promise<ICurrentUser | never> {
    const endpoint = 'UserProfile/Authenticate';
    try {
      const params = { username, password, oldPassword };
      return await this.Post(params, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async UpdatePasswordAndAuthenticate(username: string, password: string, oldPassword:string): Promise<ICurrentUser | never> {
    const endpoint = 'UserProfile/UpdatePasswordAndAuthenticate';
    try {
      const params = { username, password, oldPassword };
      return await this.Post(params, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async CheckStatus(): Promise<IUserProfile | never> {
    const endpoint = 'UserProfile/CheckStatus';
    try {
      return await this.CustomGetNoParams( endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async SendResetRequest(data: { email: string }): Promise<boolean | never> {
    const endpoint = 'UserProfile/SendResetRequest';
    try {
      return await this.CustomGet(data, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async UpdateUserInfo(data: {id:string, model:any}): Promise<IUserProfile[] | never>{
    try{
      const endpoint = 'UserProfile/UpdateUserInfo';
      return await this.CustomPut(data,endpoint);
    }catch(error){
      return ErrorHandler.catchApiError((error as AxiosError));
    }
  }

  public async UpdateUserPassword(data: {id:string, model:any}): Promise<IUserProfile[] | never>{
    try{
      const endpoint = 'UserProfile/UpdateUserPassword';
      return await this.CustomPut(data,endpoint);
    }catch(error){
      return ErrorHandler.catchApiError((error as AxiosError));
    }
  }

  public async Logout(): Promise<IUserProfile | never> {
    const endpoint = 'UserProfile/Logout';
    try {
      return await this.CustomGetNoParams( endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  /**
   * Sends a user's signup data to the API which creates a new user
   * This function can be used by any
   * @param signUpData
   * @returns ?? Token or current user not implemented yet
   */
  public async SignUp(signUpData: IUserProfile): Promise<ICurrentUser | never> {
    const endPoint = 'UserProfile/CreateNewUser';

    try {
      return await this.Post(signUpData, endPoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
}
