import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import { Category } from '../data/utility/category';

import ProductListByCategory from '../pages/product-pages/product-list-by-category';

// render - sample page
const SamplePage = Loadable(lazy(() => import('../pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const WinfieldRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'cakes-cheesecakes-list',
          element: <ProductListByCategory category={Category.cakes} />
        },
        {
          path: 'ice-cream-list',
          element: <ProductListByCategory category={Category.iceCreams} />
        },
        {
          path: 'chocolate-list',
          element: <ProductListByCategory category={Category.chocolates} />
        },
        {
          path: 'cookies-brownies-list',
          element: <ProductListByCategory category={Category.cookies} />
        },
        {
          path: 'truffles-list',
          element: <ProductListByCategory category={Category.truffles} />
        },
        {
          path: 'coffees-drinks-list',
          element: <ProductListByCategory category={Category.coffees} />
        },
        {
          path: 'texans-list',
          element: <ProductListByCategory category={Category.goTexans} />
        },
        {
          path: 'seasonal-list',
          element: <ProductListByCategory category={Category.seasonal} />
        },
        {
          path: 'winfields-home-page',
          element: <SamplePage />
        },
        {
          path: '/',
          element: <SamplePage />
        }
      ]
    }
  ]
};

export default WinfieldRoutes;
