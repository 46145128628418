import {ArtifactType} from "../utility/artifactType";
import {ArtifactLocation} from "../utility/artifactLocation";


export let defaultFileArtifact:IFileArtifact={
    id : '',
    name : '',
    createdAt: null,
    lastModified:null,
    fileType: ArtifactType.None,
    fileLocation: ArtifactLocation.CloudFlare
}

export interface IFileArtifact{
    id:string,
    name:string,
    createdAt:Date|string|null,
    lastModified:Date|string|null,
    fileType: ArtifactType,
    fileLocation:ArtifactLocation,
}

export default class FileArtifact implements IFileArtifact{
    id:string;
    name:string;
    createdAt:Date|string|null;
    lastModified:Date|string|null;
    fileType: ArtifactType;
    fileLocation:ArtifactLocation;

    constructor(data:IFileArtifact){
        this.id = data.id;
        this.name = data.name;
        this.createdAt = data.createdAt;
        this.lastModified = data.lastModified;
        this.fileLocation = data.fileLocation;
        this.fileType = data.fileType;
    }
}