import { BaseReactService } from './interfaces/BaseReactService';
import { ErrorHandler } from '../../utils/ErrorHandler';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Ownership } from '../../data/utility/ownership';
import { IProductItem } from '../../data/models/productItem';
import { Category } from '../../data/utility/category';
import {Status} from "../../data/utility/ItemStatus";

/**
 * This is a User Data Service which gets data from the server with basic crud operations.
 * Adding additional functions here will allow the user to get to different routes if needed.
 */
export class ProductItemService extends BaseReactService {
  constructor() {
    super('ProductItem/');
  }

  public async GetProductsByOwnership(data: { company: Ownership }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetProductsByOwnership';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetActiveProductsByOwnership(data: { company: Ownership }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetActiveProductsByOwnership';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetProductsByCategory(data: { company: Ownership; category: Category }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetProductsByCategory';

    try {
      return await this.CustomGet(data, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetProductsByCategoryAndStatus(data: { company: Ownership; category: Category; status:Status }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetProductsByCategoryAndStatus';

    try {
      return await this.CustomGet(data, endpoint);

    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetActiveProductsByCategory(data: { company: Ownership; category: Category }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetActiveProductsByCategory';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetFeaturedByOwnership(data: { company: Ownership }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetFeaturedByOwnership';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetActiveFeaturedByOwnership(data: { company: Ownership }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetActiveFeaturedByOwnership';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetProductBySlug(data: { slug: string }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetProductBySlug';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetActiveProductBySlug(data: { slug: string }): Promise<IProductItem[] | never> {
    const endpoint = 'ProductItem/GetActiveProductBySlug';

    try {
      return await this.CustomGet(data, endpoint);
      // return await this.Get(params,endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async UpdateItem(data: {id:string, model:any}): Promise<IProductItem[] | never>{
    try{
      const endpoint = 'ProductItem/UpdateItem';
      return await this.CustomPut(data,endpoint);
    }catch(error){
      return ErrorHandler.catchApiError((error as AxiosError));
    }
  }


  public async UpdateOrderOnly(data:{ pos: string, model:any}): Promise<IProductItem[] | never>{
    try {
      const endpoint = 'ProductItem/UpdateOrder';

      return await this.UpdateOrder(data.pos, data.model, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError((error as AxiosError));
    }
  }

}
