// third-party
import { combineReducers } from 'redux';


// project import

import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    snackbar,
    product: productReducer
});

export default reducers;
