import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT } from '../store/reducers/actions';
import authReducer from '../store/reducers/auth';

// project import
import axios from 'axios';
import { AuthProps, JWTContextType } from '../types/auth';
import { UserController } from '../_api/controllers/UserController';
import Roles from "../data/login/Roles";
import useConfig from "../hooks/useConfig";
import Loader from "../components/Loader";
import useAuth from "../hooks/useAuth";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

// constant
const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const {onChangeCompany, companyDirection} = useConfig();
    const userController: UserController = new UserController();

    useEffect(() => {
        const init = async () => {
            try {


                if(companyDirection == 'Pixel'){
                    dispatch({
                        type:LOGOUT,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    })
                }

                const response = await userController.CheckStatus();
                //@ts-ignore
                let loggedUser = response.result;
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: loggedUser
                    }
                });

            } catch (err) {
                onChangeCompany('Pixel','default');
                dispatch({
                    type: LOGOUT,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    const login = async (userName: string, password: string) => {
        const oldPassword = "";
        const response = await userController.Authenticate(userName, password, oldPassword);
        const user = {
            email: response.username,
            name: response.username,
            firstName: response.firstName,
            lastName: response.lastName,
            id: response.id,
            prefix: '',
            description: '',
            password: '',
            passwordLastChanged: null,
            createdAt: null,
            lastModified: null,
            passwordExpired: false,
            role: response.roles,
        };
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                isInitialized: true,
                user:user
            }
        });
    };

    const UpdatePasswordAndLogin = async (email: string, password: string, oldPassword:string) => {

        const response = await userController.UpdatePasswordAndAuthenticate(email, password, oldPassword);
        const user = {
            email: response.username,
            name: response.username,
            firstName: response.firstName,
            lastName: response.lastName,
            id: response.id,
            prefix: '',
            description: '',
            password: '',
            passwordLastChanged: null,
            createdAt: null,
            lastModified: null,
            passwordExpired: false,
            role: response.roles
        };
        dispatch({
            type: LOGOUT,
            payload: {
                isLoggedIn: false,
                user: null
            }
        });
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified
        const id = null
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        const response = userController.Logout();

        onChangeCompany('Pixel','default');
        dispatch({ type: LOGOUT,
            payload: {
                isLoggedIn: false,
                user: null
            }});
    };

    const resetPassword = async (email: string) => {};

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, UpdatePasswordAndLogin, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
