// material-ui
import { useTheme } from '@mui/material/styles';

import becksLogoIcon from '../../assets/images/BecksLogo_Blue_200px.png';
import winfieldsLogoIcon from '../../assets/images/WCB_1925.svg';
import bouncingLogoIcon from '../../assets/images/Bouncing_logo.png';
import useConfig from '../../hooks/useConfig';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();
  const { companyDirection } = useConfig();

  return (
    <>
      {companyDirection === 'Pixel' && <img src={bouncingLogoIcon} alt="Logo" width="100" />}
      {companyDirection === 'Becks' && <img src={becksLogoIcon} alt="Logo" width="50" />}
      {companyDirection === 'Winfields' && <img src={winfieldsLogoIcon} alt="Logo" width="100" />}
    </>
  );
};

export default LogoIcon;
