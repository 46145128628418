import { AxiosError } from 'axios';
import { ErrorHandler } from '../../utils/ErrorHandler';
import { ProductItemService } from '../services/ProductItemService';
import { IProductItem } from '../../data/models/productItem';
import { Ownership } from '../../data/utility/ownership';
import { Category } from '../../data/utility/category';
import {Status} from "../../data/utility/ItemStatus";
import toast from "react-hot-toast";

/**
 *  This is a controller class, that utilizes the service of a data model.
 *  This is where manipulation of data can be done either before or after sending data.
 **/
export class ProductItemsController {
  private productItemService: ProductItemService;

  constructor() {
    this.productItemService = new ProductItemService();
  }

  // Sends a POST request to create a module
  public async Create(product: IProductItem): Promise<IProductItem | never> {
    try {
      const endpoint = 'ProductItem/CreateNewItem';
      return this.productItemService.Post(product, endpoint);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async Update(product: IProductItem) : Promise<IProductItem | never>{
    try {
      return this.productItemService.Put(product.id, product);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  //This takes into account category changes and order shifting
  public async UpdateItem(product: IProductItem): Promise<IProductItem[] | never> {
    try {
      let data={
        id:product.id,
        model:product
      }

      let response = await this.productItemService.UpdateItem(data);
      toast.success(product.name+" saved!");
      return response

    } catch (error) {
      toast.error(product.name+" failed to save.");
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetProducts(): Promise<IProductItem[]|never> {
    try {
      return this.productItemService.GetAll();
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  // sends a GET request to get an array of modules
  public async GetProductItem(id: string): Promise<IProductItem|never> {
    try {
      let data = {
        id: id
      };
      return this.productItemService.Get(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetProductsByOwnership(ownership: Ownership): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership
      };

      return this.productItemService.GetProductsByOwnership(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetActiveProductsByOwnership(ownership: Ownership): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership
      };

      return this.productItemService.GetActiveProductsByOwnership(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetProductsByCategory(ownership: Ownership, category: Category): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership,
        category: category
      };

      return this.productItemService.GetProductsByCategory(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetProductsByCategoryAndStatus(ownership: Ownership, category: Category, status:Status): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership,
        category: category,
        status:status,
      };

      return this.productItemService.GetProductsByCategoryAndStatus(data);

    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async GetActiveProductsByCategory(ownership: Ownership, category: Category): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership,
        category: category
      };

      return this.productItemService.GetActiveProductsByCategory(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetFeaturedByOwnership(ownership: Ownership): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership
      };

      return this.productItemService.GetFeaturedByOwnership(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetActiveFeaturedByOwnership(ownership: Ownership): Promise<IProductItem[]|never> {
    try {
      let data = {
        company: ownership
      };

      return this.productItemService.GetActiveFeaturedByOwnership(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetProductBySlug(slug: string): Promise<IProductItem[]|never> {
    try {
      let data = {
        slug: slug
      };

      return this.productItemService.GetProductBySlug(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
  public async GetActiveProductBySlug(slug: string): Promise<IProductItem[]|never> {
    try {
      let data = {
        slug: slug
      };

      return this.productItemService.GetActiveProductBySlug(data);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async DeleteProduct(id: string) {
    try {
      return await this.productItemService.Delete(id);
    } catch (error) {
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async DeleteProductItem(id: string) {
    try {
      let response= await this.productItemService.DeleteItem(id);
      toast.success("Product Deleted!");
      return response
    } catch (error) {
      toast.error("Failed to delete Product !");
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }

  public async UpdateOrder(pos: string, model: any): Promise<IProductItem[] | never>{
    try {
      let response = await this.productItemService.UpdateOrderOnly({ pos, model});
      toast.success(model.name+" moved!");
      return response;

    } catch(error){
      toast.error("Failed to move " +model.name);
      return ErrorHandler.catchApiError(error as AxiosError);
    }
  }
}
