import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase, CardContent,
    ClickAwayListener, Grid,
    Paper,
    Popper,
    Stack, Tab, Tabs, Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// project import
import useConfig from '../../../../hooks/useConfig';
import Search from './Search';

import Profile from './Profile';
import Localization from './Localization';

import {useNavigate} from "react-router";
import {LogoutOutlined, PlusOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import * as React from "react";


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const history = useNavigate();

  const localization = useMemo(() => <Localization />, [i18n]);



  const handleAddProduct =()=>{
      history('/product-details/C0');
  }

    function AddButton() {
        return (
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAddProduct}>
                        <Typography variant="subtitle1">Add Product</Typography>
                    </Button>
                </Stack>
            </Box>
        );
    }

  return (
    <>
      {!matchesXs && <Search />}

      {!matchesXs && <AddButton/>}
      {!matchesXs && <Profile />}
    </>
  );
};

export default HeaderContent;
