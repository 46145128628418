import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import ProductListByCategory from "../pages/product-pages/product-list-by-category";
import {Category} from "../data/utility/category";

// render - sample page
const SamplePage = Loadable(lazy(() => import('../pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const BecksRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'becks-home-page',
          element: <SamplePage />
        },
        {
          path: 'starters-list',
          element: <ProductListByCategory category={Category.starters} />
        },
        {
          path: 'hamburgers-hotdogs-list',
          element: <ProductListByCategory category={Category.hamburgersHotdogs} />
        },
        {
          path: 'sandwiches-list',
          element: <ProductListByCategory category={Category.sandwiches} />
        },
        {
          path: 'salads-list',
          element: <ProductListByCategory category={Category.salads} />
        },
        {
          path: 'classics-list',
          element: <ProductListByCategory category={Category.classics} />
        },
        {
          path: 'dinner-list',
          element: <ProductListByCategory category={Category.dinners} />
        },
        {
          path: 'kids-list',
          element: <ProductListByCategory category={Category.kids} />
        },
        {
          path: 'sides-fries-list',
          element: <ProductListByCategory category={Category.sides} />
        }, {
          path: 'shakes-drinks-list',
          element: <ProductListByCategory category={Category.drinks} />
        },
      ]
    }
  ]
};

export default BecksRoutes;
