import React from 'react';
import useConfig from '../../hooks/useConfig';
import { Category } from '../../data/utility/category';
import { Box, Grid } from '@mui/material';

import ProductGridView from '../../components/product/ProductGridView';
import {Status} from "../../data/utility/ItemStatus";
import Toaster from "react-hot-toast";


type listProps = {
  category: Category;
};

const ProductListByCategory: React.FC<listProps> = (props) => {
  const { companyDirection } = useConfig();
  const productItemList: { propName: string; dataType: string }[] = [
    { propName: 'name', dataType: 'string' },
    { propName: 'slug', dataType: 'string' },
    { propName: 'thumbnailLink', dataType: 'image' },
    { propName: 'description', dataType: 'string' }
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProductGridView category={props.category} status={Status.Active} company={companyDirection} propsToShow={productItemList} />
        </Grid>
        <Grid item xs={12}>
          <ProductGridView category={props.category}  status={Status.OutOfStock} company={companyDirection} propsToShow={productItemList} />
        </Grid>
        <Grid item xs={12}>
          <ProductGridView category={props.category}  status={Status.Disabled} company={companyDirection} propsToShow={productItemList} />
        </Grid>
      </Grid>
    </>
  );
};
export default ProductListByCategory;
